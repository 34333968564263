// Light theme variables
$light-background: rgb(252, 252, 252);
$light-grey-container: rgb(230, 230, 230);
$light-grey: rgb(228, 228, 228);
$light-blue: rgb(168, 194, 204);
$light-text: rgba(77, 77, 77, 0.95);

// Dark theme variables
$dark-background: rgb(70, 70, 70);
$dark-grey-container: rgb(34, 34, 34);
$dark-grey: rgb(54, 54, 54);
$dark-blue: rgb(73, 90, 106);
$dark-text: rgba(205, 222, 230, 0.9);

// Universal colors
$blue: rgb(74, 107, 140);
$white: rgb(250, 250, 250);

$font-stack: 'Noto Sans', 'Helvetica Neue', Arial, sans-serif;
