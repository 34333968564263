@import '../../../../Styles/Abstracts/variables';

.watertracker_day-controls-item {
  position: relative;
  width: 21rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.75s ease;
}

.button-loader {
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
}

button:hover {
  background: rgb(14, 44, 77);
}

.edit-button,
.drink-button {
  top: 1.25rem;
}

.backwards-button,
.finish-button {
  bottom: 1.25rem;
}

.button .edit-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.button .backwards-icon,
.button .drink-icon,
.button .finish-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.button:active {
  border: 0.2rem solid $blue;
}

.button:hover .edit-icon,
.button:hover .backwards-icon,
.button:hover .drink-icon,
.button:hover .finish-icon {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    width: 1.25rem;
    height: 1.25rem;
  }
  50% {
    width: 1rem;
    height: 1rem;
  }
  100% {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.right,
.left {
  position: absolute;
  height: 100%;
}

.left {
  left: 0.75rem;
}

.right {
  right: 3rem;
}

.drink-icon,
.edit-icon,
.backwards-icon,
.finish-icon {
  position: absolute;
  z-index: 1;
  fill: $light-background;
}

@media (max-width: 48em) {
  .watertracker_day-controls-item {
    margin-top: 1.5rem;
  }
}

@media (max-width: 23.375em) {
  .button {
    width: 2.5rem;
    height: 2.5rem;
  }

  .button .edit-icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  .button .backwards-icon,
  .button .drink-icon,
  .button .finish-icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  .left {
    left: 0.5rem;
  }

  .right {
    right: 2.75rem;
  }

  .watertracker_day-controls-item {
    width: 17rem;
  }
}
