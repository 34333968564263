.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.header-wrapper {
  text-align: center;
  margin-bottom: 3rem;
}

.title {
  font-size: 3rem;
  line-height: 3.5rem;
  color: transparent;
  opacity: 0.8;
  font-weight: 500;
  background-image: linear-gradient(90deg, #acb6e5, #74ebd5);
  background-clip: text;
  -webkit-background-clip: text;
}

.subtitle {
  font-size: 1.35rem;
  line-height: 1.35rem;
  color: transparent;
  opacity: 0.8;
  font-weight: 450;
  background-image: linear-gradient(30deg, #587771, #acb6e5);
  background-clip: text;
  -webkit-background-clip: text;
}

.buttons-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media (max-width: 23.375em) {
  .title {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .subtitle {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
