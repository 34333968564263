.theme-selection {
  position: absolute;
  bottom: 7rem;
  right: calc(50% - 4rem);
}

.theme-icon {
  width: 1.175rem;
  height: 1.175rem;
  fill: #415a77;
}
