@import '../../../Styles/Abstracts/variables';
@import '../../../Styles/Abstracts/mixins';

.today-container {
  border-radius: 0.5rem;
}

.controls-background {
  padding: 1rem;
  height: 21rem;
  margin: 0.5rem;
}

.controls-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.today {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0.25rem 0 0.5rem 0.75rem;
  text-align: center;
}

.toggler-container {
  padding: 1rem;
}

.description,
.value {
  text-align: left;
  padding-left: 0.75rem;
  margin-top: 0.25rem;
}

.description {
  font-size: 1rem;
  font-weight: 350;
}

.value {
  font-size: 1.125rem;
  margin: 0 0.5rem 0.75rem 0.7rem;
  border-bottom: rgb(247, 247, 247) 0.1rem solid;
  font-weight: 400;
}

.edit-icon {
  width: 1.5rem;
  font-size: 1.5rem;
  fill: rgba(224, 224, 224, 1);
}

.dots {
  position: absolute;
  bottom: 2.75rem;
  left: 0;
  right: 0;
}

.achievements-container {
  height: 21rem;
}

.charts-container {
  border-radius: 0.5rem;
  position: relative;
}

.toggle-buttons-container {
  position: absolute;
  right: 2.75rem;
  top: 0.25rem;
  text-transform: none;
  z-index: 2;
}

.toggle-buttons-container button {
  text-transform: none;
}

.achievements-background,
.todays-types-background,
.charts-background {
  border-radius: 0.5rem;
}

@media (max-width: 48em) {
  .today {
    margin-top: 0;
    text-align: center;
  }

  .right-side {
    right: 0.2rem;
  }
  .left-side {
    left: 0.2rem;
  }
}

@media (max-width: 62em) {
  .today-container {
    border-radius: 0;
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .today-container {
    background-color: unset;
    box-shadow: none;
  }

  .controls-background {
    padding: 0.5rem 0.5rem 1rem 0.5rem;
    background: $light-background;
    border-radius: 0.5rem;
    height: 21rem;
    margin-bottom: 0.5rem;
  }

  .achievements-background,
  .todays-types-background,
  .charts-background {
    background: $light-background;
    @include box-shadow-area-highlight();
    border-radius: 0.5rem;
    margin: 0 0.5rem 0.5rem 0.5rem;
  }

  .charts-background {
    margin: 0 0.5rem;
  }
}

@media (max-width: 56.225em) {
  .controls-background {
    height: 30rem;
  }
}

/** 37.48em = 599px */
@media (max-width: 37.48em) {
  .toggle-buttons-container {
    position: absolute;
    right: 0.75rem;
    top: 0.5rem;
    z-index: 10;
  }
}
