@import '../../../../Styles/Abstracts/variables';
@import '../../../../Styles/Abstracts/mixins';

.container {
  position: relative;
  min-height: calc(100vh - 6.5rem);
}

.lg-container {
  margin: 3rem 1rem 1rem 1rem;
  border-radius: 0.5rem;
  position: relative;
  padding: 0.125rem;
}

.progress-bar-container {
  height: 21rem;
}

.title {
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0.75rem;
}

.go-back-button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.go-back-button:hover,
.go-back-button:active {
  background-color: rgba(3, 83, 94, 0.8);
}

.go-back-icon {
  width: 1.5rem;
  fill: rgb(142, 202, 230);
}

.slide {
  background: $light-background;
  @include box-shadow-area-highlight();
  border-radius: 0.5rem;
  margin: 0.5rem;
}

@media (max-width: 56.225em) {
  .slide {
    height: 40rem;
  }
}

/**
* 599px will switch the progress bar container to the defined height
*/
@media (max-width: 37.47em) {
  .progress-bar-container {
    height: 39.75rem;
  }
}
