.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  font-size: 5rem;
  line-height: 4.25rem;
}

.sub-header {
  font-size: 1.25rem;
  margin: 2rem 0;
}
