@import '../../Abstracts/variables';

.paper {
  background-color: $light-background;
}

// Bar chart colors
.bar-chart {
  .MuiChartsAxis-root,
  .MuiChartsGrid-root {
    .MuiChartsAxis-tickLabel,
    .MuiChartsAxis-label {
      fill: $light-text;
    }
    .MuiChartsAxis-line,
    .MuiChartsGrid-horizontalLine,
    .MuiChartsGrid-verticalLine {
      stroke: $light-text;
    }
  }
}

.toggle-button {
  &.MuiToggleButton-root.MuiButtonBase-root {
    background: $light-grey;
    color: $light-text;
  }
  &.MuiToggleButton-root.MuiButtonBase-root.Mui-selected {
    background: $light-blue;
  }
}

.mui-divider {
  &.MuiDivider-root {
    border-color: $light-text;
    opacity: 0.25;
  }
}

.mui-input {
  &.MuiFormControl-root {
    /* Change border color */
    .MuiInput-underline:before {
      border-bottom: 0.08rem solid $light-text;
    }
    /* Change border color on hover */
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 0.1rem solid $light-text;
    }
    /* Change active border color */
    .MuiInput-underline:after {
      border-bottom: 0.12rem solid $light-text;
    }
    .MuiFormLabel-root {
      color: $light-text;
    }
    .MuiInputBase-input {
      color: $light-text;
      &.MuiInput-underline {
        border-bottom: 0.05rem solid $light-text;
      }
    }
    // Helper text and units "ml" color
    .MuiTypography-root,
    .MuiFormHelperText-root {
      color: $light-text;
    }
  }
}

.mui-button {
  &.MuiButtonBase-root {
    color: $light-text;
    border-color: $light-text;
    text-transform: none;
  }
}

// Loading indicator
.mui-skeleton {
  &.MuiSkeleton-root {
    background-color: $light-text;
    opacity: 0.5;
  }
}

// Dark theme
.dark {
  // Loading indicator
  .mui-skeleton {
    &.MuiSkeleton-root {
      background-color: $dark-text;
      opacity: 0.5;
    }
  }

  .mui-button {
    &.MuiButtonBase-root {
      color: $dark-text;
      border-color: $dark-text;
    }
  }

  .mui-input {
    &.MuiFormControl-root {
      /* Change border color */
      .MuiInput-underline:before {
        border-bottom: 0.08rem solid $dark-text;
      }
      /* Change border color on hover */
      .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: 0.1rem solid $dark-text;
      }
      /* Change active border color */
      .MuiInput-underline:after {
        border-bottom: 0.12rem solid $dark-blue;
      }
      .MuiFormLabel-root {
        color: $dark-text;
        opacity: 0.8;
      }
      .MuiInputBase-input {
        color: $dark-text;
        &.MuiInput-underline {
          border-bottom: 0.05rem solid $dark-text;
        }
      }
      // Helper text and units "ml" color
      .MuiTypography-root,
      .MuiFormHelperText-root {
        color: $dark-text;
        opacity: 0.5;
      }
    }
  }

  .mui-divider {
    &.MuiDivider-root {
      border-color: $dark-text;
    }
  }

  .paper {
    background-color: $dark-background;
  }

  .toggle-button {
    &.MuiToggleButton-root.MuiButtonBase-root {
      background: $dark-grey;
      color: $dark-text;
    }
    &.MuiToggleButton-root.MuiButtonBase-root.Mui-selected {
      background: $dark-blue;
    }
  }

  // Bar chart colors
  .bar-chart {
    .MuiChartsAxis-root,
    .MuiChartsGrid-root {
      .MuiChartsAxis-tickLabel,
      .MuiChartsAxis-label {
        fill: $dark-text;
      }
      .MuiChartsAxis-line,
      .MuiChartsGrid-horizontalLine,
      .MuiChartsGrid-verticalLine {
        stroke: $dark-text;
      }
    }
  }
}
