.container {
  position: relative;
  height: 21rem;
  width: 100%;
}

.title {
  padding-top: 0;
  font-size: 1.25rem;
  font-weight: 370;
  text-align: center;
}

/**
* 64em = 1024px
*/
@media (max-width: 64em) {
  .title {
    padding-top: 0.625rem;
  }
}

/**
* 74.96em = 1199.36px
*/
@media (max-width: 74.96em) {
  .chart-container {
    height: 17rem;
  }
}

/** 37.48em = 599px */
@media (max-width: 37.48em) {
  .container {
    /* 636px = 39.75rem */
    height: 39.75rem;
  }

  .loading-container {
    height: 34rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chart-container {
    height: 35rem;
  }
}
