@import '../Abstracts/variables';

.button-colors {
  background-color: $light-text;
}

.icon {
  fill: $light-text;
}

.divider-color::before {
  background-color: $light-text;
}

.dark {
  .button-colors {
    background-color: $dark-blue;
  }

  .icon {
    fill: $dark-text;
  }

  .divider-color::before {
    background-color: $dark-text;
  }
}
