@import '../../../../Styles/Abstracts/variables';

.modal-wrapper {
  position: relative;
  padding: 1.25rem 0.5rem 0.75rem 0.5rem;
  border-radius: 0.5rem;
}

.title {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
}

.sub-title {
  font-size: 1.125rem;
  font-weight: 300;
  text-align: center;
  margin-bottom: 1.5rem;
}

.date-picker__container {
  margin: 1.5rem 0.5rem;
  padding: 1rem 0;
  border-radius: 0.5rem;
  height: 15rem;
}

.close-container {
  width: 2rem;
  height: 2rem;
  background-color: rgba(225, 57, 57, 0.95);
  border: 0.1rem solid rgba(0, 0, 0, 0.34);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -2.5rem;
  right: 0.8rem;
  opacity: 0.65;
  z-index: 100;
  transition: ease-in-out 0.25s;
}

.close-container:hover {
  transform: rotate(90deg);
}

.close {
  width: 1.5rem;
  height: 1.5rem;
  fill: rgba(242, 242, 242, 0.9);
}

.arrow-container {
  width: 1.75rem;
  height: 1.75rem;
  background-color: rgb(235, 225, 221);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5rem;
  left: 1rem;
  opacity: 0.65;
  z-index: 100;
}

.arrow-container:active {
  background-color: rgba(223, 171, 158, 0.9);
}

.arrow {
  width: 1rem;
  height: 1rem;
  fill: rgba(27, 27, 27, 0.8);
}

@media (max-width: 21em) {
  .modal-wrapper {
    border-radius: 0;
    width: 100%;
    padding: 1.25rem 0 0.75rem 0;
  }
  .date-picker__container {
    margin: 1.5rem 0;
    padding: 1rem 0;
    border-radius: 0;
  }
}
