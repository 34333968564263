@import '../../Styles/Abstracts/variables';

.menu-icon {
  fill: rgba(3, 4, 94, 0.8);
  width: 3rem;
  position: fixed;
  right: 0.85rem;
  top: 0.85rem;
  background-color: $light-background;
  border: $light-text 0.05rem solid;
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 0.5rem;
  padding: 0.5rem;
  z-index: 1000;
}

.icon {
  width: 1rem;
  margin-right: 1rem;
}

.menu-icon:hover {
  fill: $blue;
}

.menu-button {
  font-size: 1.25rem;
  font-weight: 400;
  text-align: left;
  padding: 1.25rem 2rem;
  border: none;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.theme-icon {
  width: 1.175rem;
  height: 1.175rem;
  fill: #415a77;
}

@media (max-width: 48em) {
  .menu-icon {
    width: 2.75rem;
  }
}
