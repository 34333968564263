@import "../Abstracts/variables";

@font-face {
  font-family: "Noto Sans";
  src: url("../../Assets/NotoSans-VariableFont_wdth\,wght.ttf")
    format("truetype");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

link,
button {
  font-family: $font-stack;
}
