@import '../../../Styles/Abstracts/variables';
@import '../../../Styles/Abstracts/mixins';

.loading-container {
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
}

.loading-text {
  font-size: 1rem;
  font-weight: 400;
}

.container {
  border-radius: 0.5rem;
  margin: 0;
  @include box-shadow-area-highlight();
  min-height: 26rem;
}

.container-title {
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 1.25rem;
  font-weight: 400;
  text-align: center;
}

.chart-container {
  height: 17rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stats-container {
  min-height: 5rem;
}

/** 62em = 992px */
@media (max-width: 62em) {
  .container {
    margin: 0 0.5rem;
  }
}

/** 37.48em = 599px */
@media (max-width: 37.48em) {
  .container {
    min-height: 30.5rem;
  }
}

/** 25em = 400px */
@media (max-width: 25em) {
  .title {
    font-size: 1rem;
  }
}
