@import '../../../../Styles/Abstracts/variables';

.statistics-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 0;
  margin: 0 0.5rem;
}

.info {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 400;
}

.border-right::before {
  content: '';
  position: absolute;
  right: -0.1rem;
  top: 0.25rem;
  height: calc(100% - 0.5rem);
  width: 0.1rem;
  border-radius: 25%;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 50%;
  margin-top: 0.25rem;
  margin-bottom: 0.35rem;
}

.extended-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.75rem;
  width: 4.5rem;
  border-radius: 50%;
  margin-top: 0.25rem;
  margin-bottom: 0.35rem;
}

.info .icon {
  height: 1.25rem;
  width: 1.25rem;
}

.info .bottle-icon {
  height: 1.5rem;
  width: 1rem;
}

.highlight {
  font-size: 0.8rem;
  font-weight: 700;
}

/** 37.48em = 599px */
@media (max-width: 37.48em) {
  .info {
    font-size: 0.9rem;
    margin: 0.75rem 0;
  }
  .highlight {
    font-size: 0.9rem;
  }
  .statistics-container {
    position: relative;
    padding: 0.7rem 0 0 0.5rem;
  }

  /** line as after element to separate statistics from the element on top */
  .statistics-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 1rem;
    width: calc(100% - 2rem);
    height: 0.1rem;
    border-radius: 25%;
  }
}
