.container {
  position: relative;
  width: 100%;
  height: 21rem;
}

.chart-container {
  height: 85%;
}

/** 37.48em = 599px */
@media (max-width: 37.48em) {
  .container {
    /* 636px=39.75rem */
    height: 39.75rem;
  }
  .chart-container {
    height: 90%;
    padding-bottom: 1.75rem;
  }
  .loading-container {
    height: 34rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
