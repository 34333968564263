@import '../../../../../Styles/Abstracts/variables';

.container {
  position: relative;
  width: 100%;
  height: 21rem;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17rem;
}

.title-container {
  height: 3rem;
}

.chart-container {
  height: 17rem;
}

.no-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 1.75rem);
  left: calc(50% - 6rem);
  height: 5.5rem;
  width: 12rem;
  z-index: 1;
}

.legend-container {
  position: relative;
  height: 15.5rem;
}

li.list-item {
  padding: 0.5rem 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.type-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  fill: $blue;
  width: 1.5rem;
  height: 1.5rem;
}

.no-data-text {
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
}

.title {
  font-size: 1.25rem;
  font-weight: 370;
  margin-top: 0.75rem;
  margin-left: 1.25rem;
}

ul.list {
  font-size: 1rem;
  font-weight: 350;
  padding-right: 1rem;
}

.color-box {
  width: 0.85rem;
  height: 0.85rem;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  border: white 0.05rem solid;
  border-radius: 50%;
}

.list__background {
  position: relative;
  margin: 0 0.75rem;
  border-top: 0.25rem solid transparent;
  border-bottom: 0.25rem solid transparent;
  border-radius: 0.5rem;
}

/** 37.48em = 599px */
@media (max-width: 37.48em) {
  .title-container {
    height: 2rem;
  }
  .chart-container {
    height: 35.5rem;
  }

  .container {
    /* 636px = 39.75rem */
    height: 39.75rem;
  }
  .legend-container {
    height: 33rem;
  }
}

/**
* 74.96em = 1199.36px
*/
@media (max-width: 74.96em) {
  .title {
    margin-top: 0.7rem;
  }
}
