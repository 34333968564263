@import '../../../../Styles/Abstracts/variables';

.progress-bar {
  position: relative;
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  margin: 0;
}

.text-numbers-bottom {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.message-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 350;
  text-align: center;
  border-radius: 50%;
  width: 9.25rem;
  height: 9.25rem;
}

.text-numbers-top {
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.chart-container {
  width: 17rem;
  height: 17rem;
  position: relative;
}

.chart-container::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 50%;
  background-color: $light-blue;
}

.skeleton-container {
  height: 17rem;
  padding-top: 2.25rem;
}

.message-container {
  padding-top: 2.5rem;
  border-radius: 50%;
}
