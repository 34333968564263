@import '../../../../Styles/Abstracts/variables';

.modal-wrapper {
  padding: 1rem;
  border-radius: 0.5rem;
  width: 30rem;
}

.warning-message {
  font-size: 1rem;
  text-align: center;
  margin-top: 0.25rem;
  height: 1.5rem;
}

.control-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
}

.title {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1.125rem;
  margin-top: 0.125rem;
}

@media (max-width: 48em) {
  .modal-wrapper {
    border-radius: 0;
    width: 100%;
  }
}
