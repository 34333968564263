.wrapper {
  height: 5rem;
}

.message {
  font-size: 1.125rem;
  font-weight: 400;
  text-align: center;
}

.copyright {
  font-size: 0.8rem;
  text-align: center;
  font-weight: 350;
}

@media (max-width: 62em) {
  .message {
    font-size: 0.9rem;
  }
  .copyright {
    font-size: 0.7rem;
  }
}
