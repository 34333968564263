@import '../../../../Styles/Abstracts/variables';

.container {
  height: 21rem;
}

.statistics-container {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.title {
  font-size: 1.125rem;
  font-weight: 370;
  margin: 1.5rem 0 0.5rem 1.5rem;
  padding-top: 0.5rem;
}

.statistics-item {
  position: relative;
  margin: 0 0.4rem;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.125rem;
  font-weight: 370;
  margin: 0.5rem 0 0.5rem 0.5rem;
}

.icon {
  height: 1.25rem;
  width: 1.25rem;
  margin: 0.2rem 0.5rem 0 0;
}

.badge-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 12rem;
  margin-top: 1.5rem;
}

.badge-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(83, 174, 171);
  border-radius: 50%;
  height: 9rem;
  width: 9rem;
}

.drinks-badge-icon,
.empty-badge-icon,
.finished-days-icon,
.amount-badge-icon {
  fill: rgba(21, 21, 21, 0.85);
}

.drinks-badge-icon {
  height: 4.75rem;
  width: 4.75rem;
}

.empty-badge-icon {
  height: 7rem;
  width: 7rem;
}

.finished-days-icon {
  height: 7rem;
  width: 7rem;
}

.amount-badge-icon {
  height: 6.5rem;
  width: 6.5rem;
}

.border-right::before {
  content: '';
  position: absolute;
  right: -0.1rem;
  top: 0.25rem;
  height: calc(100% - 0.5rem);
  width: 0.125rem;
  border-radius: 25%;
}

.border-color-dark::before {
  background: $dark-text;
}

.border-color-light::before {
  background: $light-text;
}

/** 1200px = 75em */
@media (max-width: 75em) {
  .statistics-container {
    border-radius: 0;
  }
}

/** 992px = 62em */
@media (max-width: 62em) {
  .statistics-container {
    background-color: unset;
    border-radius: 0;
  }
  .title {
    padding-top: 1rem;
    margin-top: 0;
  }
}

/** 37.48em = 599px */
@media (max-width: 37.48em) {
  .container {
    height: 39.75rem;
  }
  .content {
    justify-content: start;
    font-size: 1.1rem;
    margin: 0.25rem 0 0.25rem 0.25rem;
  }
  .title {
    font-size: 1.25rem;
    padding-top: 1rem;
    margin-bottom: 1rem;
  }
  .icon {
    margin: 0.2rem 0.5rem 0 0.75rem;
  }
  .badge-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 11.25rem;
  }
}
