// Abstracts
@import 'Abstracts/variables';

// Base
@import 'Base/typography';
@import 'Base/global';

// Components
@import 'Components/basics';
@import 'Components/MUI/overwrites';

// Layout

// Pages

*,
*::before,
*::after {
  box-sizing: border-box;
}
