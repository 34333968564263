.arrow-container {
  width: 1.75rem;
  height: 1.75rem;
  background-color: rgb(235, 225, 221);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0.75rem;
  opacity: 0.65;
  z-index: 100;
}

.arrow-container:active {
  background-color: rgba(223, 171, 158, 0.9);
}

.arrow {
  width: 1rem;
  height: 1rem;
  fill: rgb(27, 27, 27);
}

.right-side {
  right: 0.75rem;
}

.left-side {
  left: 0.75rem;
}

.dots {
  position: absolute;
  bottom: 2.75rem;
  left: 0;
  right: 0;
}
