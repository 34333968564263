@import '../Abstracts/variables';
@import '../Abstracts/mixins';

// Global colors
body {
  color: $light-text;
  background-color: $light-grey;
}

// Basic content background
.content-background {
  background-color: $light-background;
}

// Highlighted or area content background
.highlighted-content-background {
  background-color: $light-blue;
}

// Extra class for text passages
.text {
  color: $light-text;
}

// Blue background on hover
.blue-selection:hover {
  background-color: $light-blue;
}

// Background for modals
.modal-background {
  background-color: $light-background;
  border: 0.01rem solid $light-text;
}

// Dark theme
body {
  &.dark {
    color: $dark-text;
    background-color: $dark-grey;
  }
}

// Box shadow to highlight an area
.box-shadow {
  @include box-shadow-area-highlight();
}

.dark {
  // Blue background on hover
  .blue-selection:hover {
    background-color: $dark-blue;
  }

  // Background for modals
  .modal-background {
    background-color: $dark-grey-container;
    border: 0.08rem solid $dark-text;
  }

  // Basic content background
  .content-background {
    background-color: $dark-grey-container;
  }

  // Highlighted or area content background
  .highlighted-content-background {
    background-color: $dark-blue;
  }

  // Extra class for text passages
  .text {
    color: $dark-text;
  }

  // Box shadow to highlight an area
  .box-shadow {
    @include box-shadow-area-highlight-dark();
  }
}

body {
  font-family: $font-stack;
  user-select: none;
}
